import React from 'react';
import '../styles/text.less';
import '../styles/divisions.less';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { ReactComponent as LogoReal } from './assets/logo-real.svg';
import { ReactComponent as LogoAreal } from './assets/logo-areal.svg';
import { ReactComponent as LogoLineareal } from './assets/logo-lineareal.svg';
import { ReactComponent as LogoDreimal } from './assets/logo-dreimal.svg';
import Layout from '../components/Layout';
import Product from '../components/Product';

const Divisions = () => {
  const intl = useIntl();
  return (
  <Layout>
    <div>
      
      <div className="textcontent meta divisions">
        <FormattedHTMLMessage id="divisions.headline" />
        {/* REAL */}
        <a href={intl.formatMessage({ id: 'divisions.real.url' })}>
          <div className="sectionlogo">
            <LogoReal />
          </div>
          <div className="sectionintro">
            <FormattedHTMLMessage id="divisions.real.text" />
          </div>
        </a>
        {/* AREAL */}
        <a href={intl.formatMessage({ id: 'divisions.areal.url' })}>
          <div className="sectionlogo">
            <LogoAreal />
          </div>
          <div className="sectionintro">
            <FormattedHTMLMessage id="divisions.areal.text" />
          </div>
        </a>
        {/* LINEAREAL */}
        <a href={intl.formatMessage({ id: 'divisions.dreimal.url' })}>
          <div className="sectionlogo">
            <LogoDreimal />
          </div>
          <div className="sectionintro">
            <FormattedHTMLMessage id="divisions.dreimal.text" />
          </div>
        </a>
        {/* DREIMAL */}
        {/* <a href={intl.formatMessage({ id: 'divisions.dreimal.url' })}>
          <div className="sectionlogo">
            <LogoDreimal />
          </div>
          <div className="sectionintro">
            <FormattedHTMLMessage id="divisions.dreimal.text" />
          </div>
        </a> */}
        {/* <Product id="realith" /> */}

      </div>
    </div>
  </Layout>
  )
};

export default Divisions;
